import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import { Button, Container, Div, Image, Text } from '@pinpoint/design-kit';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Div w="100wv" h="100vh" d="flex" flexDir="column" align="center" justify="center" pos="relative">
          <Image
            src="/static/login-back.png"
            srcSet="/static/login-back@2x.png 2x,/static/login-back@3x.png 3x,"
            alt="background"
            w="100vw"
            h="100vh"
            pos="absolute"
            top="0"
            style={{
              objectFit: 'cover',
            }}
          />
          <Div
            w={{ xs: '100%', lg: '768px' }}
            rounded="md"
            shadow="5"
            bg="black50"
            p={{ x: '1.5rem', y: '1.25rem' }}
            m={{ x: '2rem', y: '2rem' }}
            zIndex="10"
            style={{
              backdropFilter: 'blur(8px)',
            }}
          >
            <Container d="flex" flexDir="column" align="center">
              <Image w="250px" src="/static/on-point.svg" m={{ x: '2rem', y: '1rem' }} />
              <Text
                fontFamily="secondary"
                textSize="title"
                textColor="rioGrande"
                textAlign="center"
                m={{ x: '2rem', y: '1.25rem' }}
              >
                Something went wrong.
              </Text>
              <Text m={{ x: '2rem', t: '1rem', b: '2.5rem' }} textAlign="left" textColor="white" textSize="subheader">
                We apologize for the inconvenience. You can submit a crash report to help our engineers resolve this
                issue quickly.
              </Text>
              <Button
                m={{ x: '2rem', y: '1rem' }}
                bg="brand500"
                hoverBg="brand600"
                onClick={() => {
                  Sentry.showReportDialog({ eventId });
                }}
                textSize="14"
              >
                SUBMIT CRASH REPORT
              </Button>
              <Button
                m={{ x: '2rem', y: '1rem' }}
                bg="brand500"
                hoverBg="brand600"
                onClick={() => {
                  window.location = '/';
                }}
                textSize="14"
              >
                TAKE ME HOME
              </Button>
            </Container>
          </Div>
        </Div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
