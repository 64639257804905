import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Reset } from 'styled-reset';
import { ThemeProvider } from '@pinpoint/design-kit';

import DefaultTheme from 'src/styles/DefaultTheme';

import { createGlobalStyle } from 'styled-components';

import { ErrorBoundary } from 'src/components';
import Auto1Calc from 'src/components/calc_auto_1';
import Home1Calc from 'src/components/calc_home_1';

import { ROUTES } from 'src/config';

const GlobalStyle = createGlobalStyle`
  @media (max-width: ${DefaultTheme.grid.breakpoints.lg}px) {
    html {
      font-size: 14px;
    }
  }
  @media (min-width: ${DefaultTheme.grid.breakpoints.lg}px) {
    html {
      font-size: 16px;
    }
  }
  body {
    font-family: Roboto;
    margin: 0;
    padding: 0;
  }
`;

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <ThemeProvider theme={DefaultTheme}>
        <Reset />
        <ErrorBoundary>
          <Switch>
            <Route exact path={ROUTES.HOME_ROI_1} component={Home1Calc} />
            <Route exact path={ROUTES.AUTO_ROI_1} component={Auto1Calc} />
            <Route
              path={ROUTES.HOME}
              render={() => (
                <Redirect
                  to={{
                    pathname: ROUTES.HOME_ROI_1,
                  }}
                />
              )}
            />
          </Switch>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}

App.propTypes = {};

export default App;
