import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import App from 'src/App';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV || 'local',
    release: process.env.REACT_APP_SENTRY_RELEASE || 'local',
    integrations: [
      new Integrations.BrowserTracing({
        heartbeatInterval: 20000,
        idleTimeout: 60000,
        finalTimeout: 60000,
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new SentryRRWeb({}),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 3,
    maxBreadcrumbs: 50,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
