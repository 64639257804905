export default {
  PINPOINT_TENANT: 'a84eda88-4e26-447b-8c8b-778eca04d187',
  SANDBOX_TENANT: '7b50ac91-b919-4c20-9eeb-0278dcad40f9',
  FL_LIT_MODEL_TENANTS: [],
  AUTO_LRM_MODEL_IDS: {
    prod: [],
    stage: [],
  },
  MOCK: 'http://localhost:5544',
  ENG_TEAM: [
    'wei@pinpoint.ai',
    'evan@pinpoint.ai',
    'andres.jejen@pinpoint.ai',
    'carl.eberle@pinpoint.ai',
    'ops+jenkins@pinpoint.ai',
    'avi@pinpoint.ai',
    'daniel.wyatt@pinpoint.ai',
    'jacinth.david@pinpoint.ai',
  ],
  INPUT_DISTRIBUTION: {
    INPUTS: {
      POLICY_PERIODS: 'POLICY_PERIODS',
      OTHER_EXPOSURE: 'OTHER_EXPOSURE',
      LOSS_COUNT: 'LOSS_COUNT',
      LOSS_DOLLARS: 'LOSS_DOLLARS',
      PREMIUMS: 'PREMIUMS',
    },
    FILTERS: {
      CLAIMANTS: 'CLAIMANTS',
      NON_CLAIMANTS: 'NON_CLAIMANTS',
      ALL: 'ALL',
    },
    PARAMS: {
      'policy-periods': 'POLICY_PERIODS',
      'other-exposure': 'OTHER_EXPOSURE',
      'claims-count': 'LOSS_COUNT',
      'loss-dollars': 'LOSS_DOLLARS',
      premiums: 'PREMIUMS',
    },
  },
  ONBOARDING_STATUS: {
    API: {
      RECEIVED_RAW_CLIENT_UPLOAD: 'RECEIVED_RAW_CLIENT_UPLOAD',
      RECEIVED_RAW_CLIENT_UPLOAD_FAILURE: 'RECEIVED_RAW_CLIENT_UPLOAD_FAILURE',
      ACCEPTED_RAW_CLIENT_UPLOAD: 'ACCEPTED_RAW_CLIENT_UPLOAD',
      ACCEPTED_RAW_CLIENT_UPLOAD_FAILURE: 'ACCEPTED_RAW_CLIENT_UPLOAD_FAILURE',
      PROCESSED_BY_DET: 'PROCESSED_BY_DET',
      PROCESSED_BY_DET_FAILURE: 'PROCESSED_BY_DET_FAILURE',
      DET_WAITING_FOR_CONFIRMATION: 'DET_WAITING_FOR_CONFIRMATION',
      RECEIVED_CLIENT_UPLOAD: 'RECEIVED_CLIENT_UPLOAD',
      ACCEPT_CLIENT_UPLOAD: 'ACCEPT_CLIENT_UPLOAD',
      WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
      APPROVED_BY_ADMIN: 'APPROVED_BY_ADMIN',
      READY_FOR_PROVIDER: 'READY_FOR_PROVIDER',
      EXTRACTED_FOR_PUSH: 'EXTRACTED_FOR_PUSH',
      PACKAGED_FOR_PROVIDER: 'PACKAGED_FOR_PROVIDER',
      PUSHED_TO_PROVIDER: 'PUSHED_TO_PROVIDER',
      READY_FROM_PROVIDER: 'READY_FROM_PROVIDER',
      RECEIVED_FROM_PROVIDER: 'RECEIVED_FROM_PROVIDER',
      PROFILE_MATCHED: 'PROFILE_MATCHED',
    },
    DESC: {
      RECEIVED: 'Received',
      READY: 'Ready',
      USER_CONFIRMATION_NEEDED: 'User Confirmation Needed',
      ADMIN_APPROVAL_NEEDED: 'Pinpoint Approval Needed',
      VALIDATED: 'Validated',
      APPROVED: 'Approved',
      PROCESSING: 'Processing',
      ONBOARDED: 'Onboarded',
      ERROR: 'Error',
    },
  },
  REONBOARDABLE_STATES: [
    'RECEIVED_RAW_CLIENT_UPLOAD_FAILURE',
    'ACCEPTED_RAW_CLIENT_UPLOAD_FAILURE',
    'PROCESSED_BY_DET_FAILURE',
    'DET_WAITING_FOR_CONFIRMATION',
  ],
  ONBOARDING_TYPES: {
    ENRICHMENT: 'ENRICHMENT',
    FLORIDA_LITIGATION_MODEL: 'FLORIDA_LITIGATION_MODEL',
    ENRICHMENT_PLUS_MODELING: 'ENRICHMENT_PLUS_MODELING',
    MODELING: 'MODELING',
    ONBOARDING: 'ONBOARDING',
  },
  ONBOARDING_MODELS: {
    CLASSIFIER_MODEL: 'CLASSIFIER_MODEL',
    ACTUARIAL_MODEL: 'ACTUARIAL_MODEL',
    PREDICT_MODEL: 'PREDICT_MODEL',
  },
  S3_PREFIX: {
    EXPRESS_ONBOARDING: 'upload/RAW',
    DATA_ENRICHMENT_TOOL: 'upload/RAW_DET',
    ONPOINT_WORKFLOW: 'workflow',
  },
  API: {
    STARTED: 'STARTED',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
  },
  COMPONENT: {
    VALIDATION: {
      // validation is pending
      PENDING: 'PENDING',
      // all good
      VALIDATED: 'VALIDATED',
      // validation failed
      FAILED: 'FAILED',
      // There is not enough data to validate. This is
      // from existing data failing validation
      INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
      // all good - operation is not needed
      SUPERSEDED: 'SUPERSEDED',
      // oops, something not right
      ERROR: 'ERROR',
    },
    OPERATION: {
      // Similar to validated - Ready for user action
      READY: 'READY',
      // User action has started
      STARTED: 'STARTED',
      // User action has succeeded
      SUCCEEDED: 'SUCCEEDED',
      // User action has failed
      FAILED: 'FAILED',
    },
  },
  FLYOVER: {
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    PINNED: 'PINNED',
    UNPINNED: 'UNPINNED',
  },
  SEGMENT: {
    STATUS: {
      PUSH_TAGS_REQUESTED: 'PUSH_TAGS_REQUESTED',
      PUSH_TAGS_INITIATED: 'PUSH_TAGS_INITIATED',
      PUSH_TAGS_SUCCESS: 'PUSH_TAGS_SUCCESS',
      PUSH_TAGS_FAILURE: 'PUSH_TAGS_FAILURE',
    },
    FILTER: {
      POPULATION_SNAPSHOT: 'POPULATION_SNAPSHOT',
      POPULATION_SAMPLE: 'POPULATION_SAMPLE',
      RANDOM_SAMPLE: 'RANDOM_SAMPLE',
      SAMPLE_PURCHASERS: 'SAMPLE_PURCHASERS',
      BROAD_PURCHASERS: 'BROAD_PURCHASERS',
      HIGH_SPENDERS: 'HIGH_SPENDERS',
      REPEAT_PURCHASERS: 'REPEAT_PURCHASERS',
      CLUSTERING_V1: 'CLUSTERING_V1',
      CLUSTERING_V2: 'CLUSTERING_V2',
      CLUSTERING_REGEX: /CLUSTERING_V\d+\/(?<clustering>\d+)/,
      SELECTED_CLUSTERING_REGEX: /CLUSTERING_V\d+\/(?<clustering>\d+)\/SELECTED/,
      CLASSIFIER: ['HIT', 'VALIDATION', 'MISS'],
      ACTUARIAL: ['HI FREQ', 'LO FREQ', 'ACTUARIAL'],
      TARGET: ['TARGET'],
      ALL: ['HIT', 'VALIDATION', 'MISS', 'HI FREQ', 'LO FREQ', 'ACTUARIAL', 'TARGET'],
    },
    FILTERS: {
      CLASSIFIER: 'CLASSIFIER',
      ACTUARIAL: 'ACTUARIAL',
      TARGET: 'TARGET',
      ALL: 'ALL',
    },
    TAGS: ['HIT', 'MISS', 'VALIDATION', 'TARGET', 'ACTUARIAL', 'CLUSTER', 'ACTVAL', 'HI FREQ', 'LO FREQ'],
    TAG: {
      HIT: 'HIT',
      MISS: 'MISS',
      VALIDATION: 'VALIDATION',
      TARGET: 'TARGET',
      ACTUARIAL: 'ACTUARIAL',
      CLUSTER: 'CLUSTER',
      ACTVAL: 'VALIDATION',
      'HI FREQ': 'HI FREQ',
      'LO FREQ': 'LO FREQ',
    },
    TAG_NAME: {
      HIT: 'Target Behavior Training Set (a.k.a. "HIT")',
      MISS: 'Control Behavior Training Set (a.k.a. "MISS")',
      VALIDATION: 'Blind Validation Set ("VALIDATION")',
      TARGET: 'Target Dataset ("TARGET")',
      ACTUARIAL: 'Actuarial Loss Dataset ("ACTUARIAL")',
      CLUSTER: 'User Subset ("CLUSTER")',
      ACTVAL: 'Actuarial Blind Validation Set ("VALIDATION")',
      'HI FREQ': 'High Frequency Dataset ("HI FREQ")',
      'LO FREQ': 'Low Frequency Dataset ("LO FREQ")',
    },
    MIN_VALIDATION_MATCHES: 1000,
  },
  VIEWS: {
    ALL: ['SUMMARY', 'MARKETING_TAKEAWAYS', 'PERSONALITY_EXPLORER', 'FACETS_VIEW', 'FACETS_COMPARISON', 'IMAGE'],
    SUMMARY: {
      code: 'SUMMARY',
      name: 'Summary',
      maxSegments: 0,
      imageUpload: false,
    },
    MARKETING_TAKEAWAYS: {
      code: 'MARKETING_TAKEAWAYS',
      name: 'Marketing Takeaways',
      maxSegments: 1,
      imageUpload: false,
    },
    PERSONALITY_EXPLORER: {
      code: 'PERSONALITY_EXPLORER',
      name: 'Personality Explorer',
      maxSegments: 1,
      imageUpload: false,
    },
    FACETS_VIEW: {
      code: 'FACETS_VIEW',
      name: 'Facets View',
      maxSegments: 1,
      imageUpload: false,
    },
    FACETS_COMPARISON: {
      code: 'FACETS_COMPARISON',
      name: 'Comparison',
      maxSegments: 3,
      imageUpload: false,
    },
    IMAGE: {
      code: 'IMAGE',
      name: 'Image Upload',
      maxSegments: 0,
      imageUpload: true,
    },
  },
  DND: {
    ITEMTYPE: {
      SLIDE: 'SLIDE',
    },
  },
  TAL_MODEL: {
    POSITIVE_SEGMENT_ID: 'POSITIVE_SEGMENT_ID',
    NEGATIVE_SEGMENT_ID: 'NEGATIVE_SEGMENT_ID',
    VALIDATION_SEGMENT_ID: 'VALIDATION_SEGMENT_ID',
    ACTUARIAL_SEGMENT_ID: 'ACTUARIAL_SEGMENT_ID',
    DEFAULT_QUANTILE: {
      DEFAULT: 5,
      LIFT_VERSION_1_ACT: 5,
      LIFT_VERSION_2_ACT: 5,
      LIFT_VERSION_3_ACT: 5,
    },
    STATE: {
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      MODEL_GENERATION_INITIATED: 'MODEL_GENERATION_INITIATED',
      MODEL_GENERATION_SUCCESS: 'MODEL_GENERATION_SUCCESS',
      MODEL_GENERATION_FAILURE: 'MODEL_GENERATION_FAILURE',
      LIFT_CHART_GENERATION_INITIATED: 'LIFT_CHART_GENERATION_INITIATED',
      LIFT_CHART_GENERATION_SUCCESS: 'LIFT_CHART_GENERATION_SUCCESS',
      LIFT_CHART_GENERATION_FAILURE: 'LIFT_CHART_GENERATION_FAILURE',
    },
    STATES: [
      'PENDING_APPROVAL',
      'APPROVED',
      'MODEL_GENERATION_INITIATED',
      'MODEL_GENERATION_SUCCESS',
      'MODEL_GENERATION_FAILURE',
      'LIFT_CHART_GENERATION_INITIATED',
      'LIFT_CHART_GENERATION_SUCCESS',
      'LIFT_CHART_GENERATION_FAILURE',
    ],
    QUANTILE_LABEL: {
      2: 'halves',
      3: 'tertiles',
      4: 'quartiles',
      5: 'quintiles',
      10: 'deciles',
      20: 'ventiles',
    },
    STATE_GROUPS: {
      PENDING_APPROVAL: 1,
      IN_PROGRESS: 2,
      ERRORED: 3,
      COMPLETED: 4,
    },
    STATE_GROUPINGS: {
      PENDING_APPROVAL: 1,
      APPROVED: 2,
      MODEL_GENERATION_INITIATED: 2,
      MODEL_GENERATION_SUCCESS: 2,
      LIFT_CHART_GENERATION_INITIATED: 2,
      MODEL_GENERATION_FAILURE: 3,
      LIFT_CHART_GENERATION_FAILURE: 3,
      LIFT_CHART_GENERATION_SUCCESS: 4,
      WAITING: 5,
    },
    MODEL_CLASS: {
      CLASSIFIER: 'CLASSIFIER',
      ACTUARIAL: 'ACTUARIAL',
      ALL: 'ALL',
    },
    MODEL_CLASSES: ['CLASSIFIER', 'ACTUARIAL', 'ALL'],
    SEGMENT_TYPE: {
      positive_segment_id: 'positiveSegment',
      negative_segment_id: 'negativeSegment',
      validation_segment_id: 'validationSegment',
      actuarial_segment_id: 'actuarialSegment',
    },
    FILTER: {
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      IN_PROGRESS: 'IN_PROGRESS',
      ERRORED: 'ERRORED',
      COMPLETED: 'COMPLETED',
    },
    FILTERS: ['PENDING_APPROVAL', 'IN_PROGRESS', 'ERRORED', 'COMPLETED'],
    CHARTS: {
      LINE_CHART: 'LINE_CHART',
      BAR_CHART: 'BAR_CHART',
      TORNADO_BAR_CHART: 'TORNADO_BAR_CHART',
      LINE_BAR_CHART: 'LINE_BAR_CHART',
    },
    ACT_STYLING: {
      CLASSIFIER: {
        TITLE: 'Classifer model',
        COLOR: 'tagRtClassifier',
        SHOW_ACTUAL: false,
        DISPLAY_DOLLAR: false,
        HEADER_NAME: 'CLassifier',
      },
      ACT_SEVERITY: {
        TITLE: 'Severity prediction',
        COLOR: 'actuarialSev',
        SHOW_ACTUAL: true,
        DISPLAY_DOLLAR: true,
        HEADER_NAME: 'Severity',
      },
      ACT_FREQUENCY: {
        TITLE: 'Frequency prediction',
        COLOR: 'actuarialFreq',
        SHOW_ACTUAL: false,
        DISPLAY_DOLLAR: false,
        HEADER_NAME: 'Frequency',
      },
      ACT_LOSS_MULT: {
        TITLE: 'Loss cost multiplied prediction',
        COLOR: 'actuarialMult',
        SHOW_ACTUAL: false,
        DISPLAY_DOLLAR: true,
        HEADER_NAME: 'Loss cost',
      },
      ACT_LOSS_DIRECT: {
        TITLE: 'Loss cost direct prediction',
        COLOR: 'actuarialDir',
        SHOW_ACTUAL: false,
        DISPLAY_DOLLAR: true,
        HEADER_NAME: 'Loss cost',
      },
    },
    TYPES: ['Actuarial Loss', 'Litigation', 'SIU Referral', 'Early Cancellation', 'Premium Leakage', 'Other'],
    TAGS: ['CLASSIFIER', 'ACTUARIAL', 'ACT_SEVERITY', 'ACT_FREQUENCY', 'ACT_LOSS_DIRECT', 'ACT_LOSS_MULT'],
    TAG: {
      CLASSIFIER: 'CLASSIFIER',
      ACTUARIAL: 'ACTUARIAL',
      ACT_SEVERITY: 'ACT_SEVERITY',
      ACT_FREQUENCY: 'ACT_FREQUENCY',
      ACT_LOSS_MULT: 'ACT_LOSS_MULT',
      ACT_LOSS_DIRECT: 'ACT_LOSS_DIRECT',
    },
    TAG_NAME: {
      CLASSIFIER: 'CLASSIFIER',
      ACTUARIAL: 'ACTUARIAL',
      ACT_SEVERITY: 'SEVERITY',
      ACT_FREQUENCY: 'FREQUENCY',
      ACT_LOSS_MULT: 'LOSS COST MULT',
      ACT_LOSS_DIRECT: 'LOSS COST DIRECT',
    },
    TAG_DESC: {
      CLASSIFIER: 'Binary Classifier Model ("CLASSIFIER")',
      ACTUARIAL: 'Actuarial Loss Model ("ACTUARIAL")',
      ACT_SEVERITY: 'Claims Severity Model ("SEVERITY")',
      ACT_FREQUENCY: 'Claims Frequency Model ("FREQUENCY")',
      ACT_LOSS_MULT: 'Multiplied Loss Cost Model ("LOSS COST MULT")',
      ACT_LOSS_DIRECT: 'Direct Loss Cost Model ("LOSS COST DIRECT")',
    },
    PERILS: [
      'COMMERCIAL AUTO - All Perils',
      'COMMERCIAL AUTO - Bodily Injury',
      'COMMERCIAL AUTO - Collision',
      'COMMERCIAL AUTO - Physical Damage',
      'COMMERCIAL AUTO - Other',
      'GENERAL LIABILITY',
      'HOME - Fire',
      'HOME - Hail',
      'HOME - Water',
      'HOME - Wind',
      'HOME - Other',
      "WORKERS' COMP",
      'PERSONAL AUTO - All Perils',
      'PERSONAL AUTO - Bodily Injury',
      'PERSONAL AUTO - Collision',
      'PERSONAL AUTO - Physical Damage',
      'PERSONAL AUTO - Other',
      'OTHER',
    ],
    ROI_CALC_DEFAULTS: JSON.stringify({
      acquisitionDistribution: [30, 25, 20, 15, 10, 0, 0, 0, 0, 0],
      acquisitionRate: null,
      averagePremium: null,
      baselineLift: Array(10).fill(null),
      carrierNonRenewalRate: null,
      combinedLift: Array(10).fill(null),
      hasBaselineModel: false,
      policiesInForce: null,
      riskCost: null,
      yearlyCancellationRate: null,
    }),
  },
  ROLE: {
    SUPER_ADMIN: 'SUPER_ADMIN',
    CLIENT_SERVICES_ADMIN: 'CLIENT_SERVICES_ADMIN',
    CLIENT_SERVICES: 'CLIENT_SERVICES',
    CLIENT_USER: 'CLIENT_USER',
    ADMIN: 'ADMIN',
    VIEWER: 'VIEWER',
  },
  ROLE_DESC: {
    SUPER_ADMIN: 'SUPER ADMIN',
    CLIENT_SERVICES_ADMIN: 'CLIENT SERVICES ADMIN',
    CLIENT_SERVICES: 'CLIENT SERVICES',
    CLIENT_USER: 'CLIENT USER',
    ADMIN: 'ADMIN',
    VIEWER: 'VIEWER',
    USER: 'USER',
  },
  ROLES: ['SUPER_ADMIN', 'CLIENT_SERVICES_ADMIN', 'CLIENT_SERVICES', 'CLIENT_USER', 'ADMIN', 'VIEWER'],
  // ROLES_HIDDEN is dependent on order, list from lowest to highest privilege
  ROLES_HIDDEN: [
    'VIEWER',
    'USER',
    'CLIENT_USER',
    'ADMIN',
    'CLIENT_SERVICES',
    'CLIENT_SERVICES_ADMIN',
    'SUPER_ADMIN',
    'ALL',
  ],
  ROLE_MAPPING: {
    SUPER_ADMIN: ['ADMIN', 'VIEWER'],
    ADMIN: ['ADMIN', 'VIEWER'],
    VIEWER: ['VIEWER'],
  },
  SITE_ROLES: ['SUPER_ADMIN', 'CLIENT_SERVICES_ADMIN', 'CLIENT_SERVICES', 'CLIENT_USER'],
  SITE_ROLE_MAPPING: {
    SUPER_ADMIN: ['SUPER_ADMIN', 'CLIENT_SERVICES_ADMIN', 'CLIENT_SERVICES', 'CLIENT_USER'],
    CLIENT_SERVICES_ADMIN: ['CLIENT_SERVICES_ADMIN', 'CLIENT_SERVICES', 'CLIENT_USER'],
    CLIENT_SERVICES: ['CLIENT_USER'],
  },
  ROLE_RENAMED: {
    ADMIN: 'USER',
  },
  CONTROL_STATES: {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    HIDDEN: 'HIDDEN',
  },
  FINGERPRINT: {
    PX_FACTORS: 5,
    PX_FACETS: 30,
    DEMOGRAPHICS: 8,
  },
  REAL_TIME_API: {
    CS_TYPES: ['static', 'enrichment', 'model'],
    CS_TYPE: {
      static: 'DEMO',
      enrichment: 'DATA ENRICHMENT',
      model: 'MODEL',
    },
    CS_FILTER: {
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      IN_PROGRESS: 'IN_PROGRESS',
      ERRORED: 'ERRORED',
      COMPLETED: 'READY',
    },
    CS_FILTERS: ['PENDING_APPROVAL', 'IN_PROGRESS', 'ERRORED', 'COMPLETED'],
    CS_STATES: [
      'PENDING_APPROVAL',
      'APPROVED',
      'CONFIG_GENERATION_INITIATED',
      'CONFIG_GENERATION_SUCCESS',
      'CONFIG_GENERATION_FAILURE',
    ],
    CS_STATE: {
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      CONFIG_GENERATION_INITIATED: 'CONFIG_GENERATION_INITIATED',
      CONFIG_GENERATION_FAILURE: 'CONFIG_GENERATION_FAILURE',
      CONFIG_GENERATION_SUCCESS: 'CONFIG_GENERATION_SUCCESS',
    },
    CS_STATE_GROUPS: {
      PENDING_APPROVAL: 1,
      IN_PROGRESS: 2,
      ERRORED: 3,
      COMPLETED: 4,
    },
    CS_STATE_GROUPINGS: {
      PENDING_APPROVAL: 1,
      APPROVED: 2,
      CONFIG_GENERATION_INITIATED: 2,
      CONFIG_GENERATION_FAILURE: 3,
      CONFIG_GENERATION_SUCCESS: 4,
    },
    USAGE: {
      CS_STATUS: ['PENDING_APPROVAL'],
      FILTERS: ['LAST_7_DAYS', 'LAST_30_DAYS', 'LAST_12_MONTHS', 'LAST_6_BILLING_CYCLES'],
      FILTER: {
        LAST_7_DAYS: 'LAST_7_DAYS',
        LAST_30_DAYS: 'LAST_30_DAYS',
        LAST_12_MONTHS: 'LAST_12_MONTHS',
        LAST_6_BILLING_CYCLES: 'LAST_6_BILLING_CYCLES',
      },
      FILTERS_DESC: {
        LAST_7_DAYS: 'Last 7 Days',
        LAST_30_DAYS: 'Last 30 Days',
        LAST_12_MONTHS: 'Last 12 Months',
        LAST_6_BILLING_CYCLES: 'Last 6 Billing Cycles',
      },
    },
  },
  SPLIT_NAMES: {
    CAN_CREATE_THINKALIKE_MODELS: 'can_create_thinkalike_models',
    CAN_VIEW_THINKALIKE_MODELS: 'can_view_thinkalike_models',
    CAN_VIEW_ZIP_CODE_RANKING: 'can_view_zip_code_ranking',
  },
  MIN_SEGMENT_MATCHES: 500,
  GENERAL: {
    FORM: {
      US_STATES: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'VI',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
    },
  },
};
