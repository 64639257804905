import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@pinpoint/design-kit';

import AdminButton from './admin';
import LoginButton from './login';
import PrimaryButton from './primary';
import SecondaryButton from './secondary';
import TabButton from './tab';

const ButtonTypes = {
  admin: AdminButton,
  login: LoginButton,
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tab: TabButton,
  default: Button,
};

/**
 * Button Base - Button wrapper for styled buttons
 *
 * @param {object} props
 * @param {string} [props.buttonStyle = null]
 * @param {React.ReactNode} [props.children = null]
 * @param {object} [props.userProps] - pass through props
 * @returns {React.JSX.Element}
 */
function ButtonBase({ buttonStyle, children, ...userProps }) {
  const ButtonType = ButtonTypes[buttonStyle ?? 'default'];

  if (!ButtonType) {
    throw new Error(`Invalid button type: ${buttonStyle}`);
  }

  return <ButtonType {...userProps}>{children}</ButtonType>;
}

ButtonBase.defaultProps = {
  buttonStyle: null,
  children: null,
};

ButtonBase.propTypes = {
  buttonStyle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ...Button.propTypes,
};

export default ButtonBase;
