export const noOp = () => {
  // Included to handle no-ops
};

/** calcEqPersonsAdj - adjust the losses, premiums, and % pif per deciles in equal persons case
 *
 * @param {number[]} pifPerc
 * @param {number[]} losses
 * @param {number[]} premiums
 * @return {{pinpointPIFPercentile: number[], pinpointPremiums: number[], pinpointLosses: number[]}}
 */
export function calcEqPersonsAdj(pifPerc, losses, premiums) {
  const factors = pifPerc.map((frac) => 0.1 / frac);
  return {
    pinpointPIFPercentile: pifPerc.map((elem, idx) => elem * factors[idx]),
    pinpointPremiums: premiums.map((elem, idx) => elem * factors[idx]),
    pinpointLosses: losses.map((elem, idx) => elem * factors[idx]),
  };
}
