import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { RoICalculatorV2 } from '@pinpoint/onpoint-charts';
import get from 'lodash.get';
import importedData from 'src/static/auto_1.json';

import CONSTANTS from 'src/constants';
import { calcEqPersonsAdj, noOp } from 'src/functions';

const ACT_MODEL_TYPES = CONSTANTS.TAL_MODEL.TAGS.slice(2);
const defaultCalculatorState = JSON.parse(CONSTANTS.TAL_MODEL.ROI_CALC_DEFAULTS);

export default function RoiCalculator() {
  const {
    model: { dataModelType, predictionType, typeDetail, modelData, payload },
    dataset,
  } = importedData;
  const isLegacy = ['LIFT_VERSION_1_ACT', null, undefined].includes(payload?.version);
  const isActuarialModel = ACT_MODEL_TYPES.includes(dataModelType);
  const [shouldInit, setShouldInit] = useState(true);
  const [calculatorState, setCalculatorState] = useState({ ...defaultCalculatorState });
  const liftScores = useMemo(() => {
    if ((isActuarialModel && isLegacy) || !isActuarialModel) return get(modelData, 'dataPayload.lift_scores', []);
    return get(modelData, `dataPayload.equal_persons.lift_scores`);
  }, [modelData, isActuarialModel, isLegacy]);
  const liftScore = useMemo(
    () => liftScores.find((info) => get(info, isActuarialModel ? 'p_lift' : 'lift').length === 10),
    [liftScores, isActuarialModel]
  );
  const actuarialExtraProps = useMemo(() => {
    const { pinpointPIFPercentile, pinpointLosses, pinpointPremiums } = calcEqPersonsAdj(
      get(liftScore, 'p_pif_percentile', []),
      get(liftScore, 'p_risk_cost', []),
      get(liftScore, 'p_premium', [])
    );

    return {
      baselineLift: get(liftScore, 'b_lift', calculatorState.baselineLift) || [],
      baselineLosses: get(liftScore, 'b_risk_cost', []),
      baselinePremiums: get(liftScore, 'b_premium', []),
      baselinePIFPercentile: get(liftScore, 'b_pif_percentile'),
      pinpointLosses,
      pinpointPremiums,
      pinpointPIFPercentile,
    };
  }, [liftScore, calculatorState.baselineLift]);

  const updateRoiCalcState = useCallback(
    (key, value) => {
      if (key.includes('baselineLift') || key.includes('combinedLift') || key.includes('acquisitionDistribution')) {
        const [type, idx] = key.split('.');
        calculatorState[type] = JSON.parse(JSON.stringify(calculatorState[type]));
        calculatorState[type][+idx] = +value;
      } else {
        calculatorState[key] = value;
      }
      setCalculatorState({ ...calculatorState });
    },
    [calculatorState]
  );

  const resetRoiCalcStateField = useCallback(
    (key) => {
      calculatorState[key] = defaultCalculatorState[key];
      setCalculatorState({ ...calculatorState });
    },
    [calculatorState]
  );

  useEffect(() => {
    const seed = dataset?.payload?.roi_calculator_seed;
    if (seed && isActuarialModel && typeDetail && shouldInit) {
      const multiplier = typeDetail.includes('PERSONAL AUTO') ? 2 : 1;
      setCalculatorState({
        ...defaultCalculatorState,
        yearlyCancellationRate: seed?.cancel_rate,
        acquisitionRate: seed?.new_business_rate,
        carrierNonRenewalRate: seed?.non_renewal_rate,
        policiesInForce: seed?.policies_in_force,
        averagePremium: (seed?.avg_yearly_premium ?? 0) * multiplier,
        riskCost: (seed?.avg_yearly_direct_loss_cost ?? 0) * multiplier,
      });
      setShouldInit(false);
    }
  }, [isActuarialModel, dataset, typeDetail, shouldInit]);

  return (
    <RoICalculatorV2
      corporateLayout
      modelType={dataModelType}
      riskModel={isActuarialModel ? CONSTANTS.TAL_MODEL.TAG[dataModelType] : predictionType}
      riskModelDetail={typeDetail}
      pinpointLift={get(liftScore, isActuarialModel ? 'p_lift' : 'lift', [])}
      onChange={updateRoiCalcState}
      onSave={noOp}
      acquisitionDistribution={calculatorState.acquisitionDistribution}
      acquisitionRate={calculatorState.acquisitionRate}
      averagePremium={calculatorState.averagePremium}
      baselineLift={calculatorState.baselineLift}
      carrierNonRenewalRate={calculatorState.carrierNonRenewalRate}
      combinedLift={calculatorState.combinedLift}
      hasBaselineModel={calculatorState.hasBaselineModel || isActuarialModel}
      policiesInForce={calculatorState.policiesInForce}
      riskCost={calculatorState.riskCost}
      yearlyCancellationRate={calculatorState.yearlyCancellationRate}
      disableInput={false}
      resetAcquisitionDistribution={() => resetRoiCalcStateField('acquisitionDistribution')}
      setReadyExport={noOp}
      {...actuarialExtraProps}
    />
  );
}

RoiCalculator.propTypes = {};
