const theme = {
  colors: {
    black900: '#0A1F44',
    black800: '#14284B',
    black700: '#283A5B',
    black600: '#364766',
    black500: '#455571',
    black400: '#4E5D78',
    black300: '#596780',
    black200: '#627088',
    black100: '#717D92',

    gray900: '#8A94A6',
    gray800: '#98A1B1',
    gray700: '#A7AEBB',
    gray600: '#B0B7C3',
    gray500: '#C9CED6',
    gray400: '#E1E4E8',
    gray300: '#F1F2F4',
    gray200: '#F7F8F9',
    gray100: '#FAFBFB',

    grey900: '#666F75',
    grey800: '#717A80',
    grey700: '#7B868C',
    grey600: '#889197',
    grey500: '#949DA2',
    grey400: '#A1A8AD',
    grey300: '#ADB4B8',
    grey200: '#C8CCCF',
    grey100: '#D5D8DA',

    // brand500 is always primary
    // brand900: '#001221',
    // brand800: '#002a47',
    // brand700: '#00446e',
    // brand600: '#006094',
    // brand500: '#0080ba',
    // brand400: '#2098c7',
    // brand300: '#44b0d4',
    // brand200: '#6cc7e0',
    // brand100: '#98dded',

    brand900: '#C635A8',
    brand800: '#93278F',
    brand700: '#573c81',
    brand600: '#3AA6E8',
    brand500: '#0080BB',
    brand400: '#0FCEC9',
    brand300: '#00C08B',
    brand200: '#C2D500',
    brand100: '#FFBA00',

    success900: '#136A4A',
    success800: '#23825F',
    success700: '#36AB80',
    success600: '#6FCAA8',
    success500: '#A9DCC9',
    success400: '#D0EDDF',
    success300: '#EAF7F1',
    success200: '#F5FBF8',
    success100: '#FAFDFC',

    warning900: '#EF8511',
    warning800: '#F69D2C',
    warning700: '#F7AF22',
    warning600: '#FBC050',
    warning500: '#FBE0A1',
    warning400: '#FDEECB',
    warning300: '#FEF7E6',
    warning200: '#FEFAF1',
    warning100: '#FFFDF8',

    danger900: '#A32801',
    danger800: '#D13A06',
    danger700: '#F4541D',
    danger600: '#F7926F',
    danger500: '#FABEAA',
    danger400: '#FCD7CA',
    danger300: '#FDE8E1',
    danger200: '#FEF6F3',
    danger100: '#FFFBFA',

    info900: '#01408F',
    info800: '#026DD6',
    info700: '#0284FE',
    info600: '#4BA7FE',
    info500: '#83C3FE',
    info400: '#B3DAFF',
    info300: '#DCEEFF',
    info200: '#EEF7FF',
    info100: '#F8FBFF',

    black: '#000000',
    black50: 'rgba(0, 0, 0, 0.5)',
    black75: 'rgba(0, 0, 0, 0.75)',
    black95: 'rgba(0, 0, 0, 0.95)',
    shade19: '#191919',
    shade1A: '#1A1A1A',
    shade2D: '#2D2D2D',
    shade33: '#333333',
    shade4D: '#4D4D4D',
    shade66: '#666666',
    shade79: '#797979',
    shade99: '#999999',
    shadeA0: '#A0A0A0',
    shadeAF: '#AFAFAF',
    shadeBD: '#BDBDBD',
    shadecc: '#CCCCCC',
    shadeCC: '#CCCCCC',
    shadeD9: '#D9D9D9',
    shadeE6: '#E6E6E6',
    shadeF2: '#F2F2F2',
    white: '#FFFFFF',
    transparent: 'rgba(0,0,0,0)',

    disabled: '#C8CCCF',
    light: '#A7AAB0',
    medium: '#505256',
    dark: '#000000',

    selected: '#3AA6E8',
    tertiary: '#10CFC9',
    secondary: '#F2F2F2',
    tabSelected: '#666F75',
    tabRest: '#ADB4B8',
    tabDisabled: '#C8CCCF',

    shopify: '#F4F6F8',
    skyBlue: '#69BBED',
    skyBlue2: '#BFDFED',
    skyBlueLight: '#D9ECF5',
    skyBluePale: '#F5FBFE',
    blueLight: '#3AA6E8',
    blue: '#0080BB',
    blueDark: '#0D79AF',
    blueDark2: '#00496C',
    navyBlue: '#0075AE',
    navyBlueDark: '#085982',
    powderBluePale: '#E8ECEF',
    tealLight: '#3CCCA4',
    teal: '#0FCEC9',
    tealDark: '#3DB6B6',
    tealDark2: '#3DB7B7',
    greenLight: '#3DB688',
    green: '#00C08B',
    greenDark: '#2BAF83',
    greenDark2: '#168463',
    greenDarkPale: '#5CA992',
    powderGreenPale: '#E5EDEB',
    seaGreenPale: '#F4FCFA',
    rioGrande: '#C2D500',
    rioGrandeDark: '#B5C82B',
    rioGrandeDark2: '#95A72A',
    marigold: '#FFBA00',
    marigoldDark: '#EEAE13',
    marigoldDarkPale: '#E8EBCB',
    marigoldPale: '#F6F9D9',
    orange: '#F18A00',
    orangeDark: '#DF831F',
    ginger: '#FF671D',
    vividRed: '#DA3D1A',
    red: '#FE4438',
    redDark: '#E14434',
    redDark2: '#962920',
    fuchsia: '#C635A8',
    purple: '#93278F',
    purpleDark: '#892383',
    violet: '#573c81',
    grey: '#666F75',
    lightGrey: '#AFAFAF',

    radarBlue: '#0080BB',
    radarTeal: '#10CFC9',
    radarGold: '#F69627',
    radarOrange: '#FF671D',
    radarPeach: '#FF8F88',
    radarPurple: '#A952A5',
    radarViolet: '#786099',

    theme_light: '#10CFC9',
    theme_pinpoint: '#0080BB',
    theme_pinpoint1: '#10CFC9',
    theme_pinpoint2: '#F69627',
    theme_pinpoint3: '#FF671D',
    theme_pinpoint4: '#FF8F88',
    theme_pinpoint5: '#A952A5',
    theme_pinpoint6: '#786099',

    tableauBlue: '#085982',
    tableauOrange: '#BB372E',
    validation: '#00694D',
    target: '#C85520',

    talUnknown: '#C4C4C4',

    tagHit: '#A93027',
    tagMiss: '#085982',
    tagTarget: '#95A72A',
    tagValidation: '#239F78',
    tagActuarial: '#D79F1C',
    tagRtDemo: '#2CAF83',
    tagRtClassifier: '#C635A8',

    talLime: '#BDD027',
    progressTeal: '#26C9C2',

    actuarialFreq: '#246285',
    actuarialSev: '#8D390A',
    actuarialMult: '#217C7C',
    actuarialDir: '#5C0859',
    otherExposure: '#9E3D9A',
  },
  heatmap: [
    '#085982',
    '#115e85',
    '#256a8d',
    '#377493',
    '#4b809b',
    '#608ca2',
    '#7397a9',
    '#87a3b1',
    '#adb9bf',
    '#cccccc',
    '#d1c1c0',
    '#d0b6b4',
    '#cfa7a4',
    '#cc9490',
    '#c87d78',
    '#c4635d',
    '#bf463e',
    '#BB372E',
  ],
  heatmapTransparent: [
    '#115e85',
    '#256a8d',
    '#377493',
    '#4b809b',
    '#608ca2',
    '#7397a9',
    '#87a3b1',
    '#99adb7',
    '#adb9bf',
    '#cccccc',
    '#d1c1c0',
    '#d0b6b4',
    '#cfa7a4',
    '#cc9490',
    '#c87d78',
    '#c4635d',
    '#bf463e',
    '#BB372E',
  ],
  grid: {
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    containerMaxWidth: {
      xs: '540px',
      sm: '720px',
      md: '960px',
      lg: '1156px',
      xl: '1200px',
    },
    gutterWidth: '1rem',
    colCount: 12,
  },

  shadows: {
    0: 'none',
    1: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 1px 1px 0 rgba(8, 11, 14, 0.1)',
    2: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 3px 3px -1px rgba(8, 11, 14, 0.1)',
    3: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 6px 6px -1px rgba(8, 11, 14, 0.1)',
    4: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 16px 16px -1px rgba(8, 11, 14, 0.1)',
    5: '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 32px 40px -1px rgba(8, 11, 14, 0.1)',
    6: '0px 3px 15px rgba(0, 0, 0, 0.05)',
  },

  fontFamily: {
    primary: 'Roboto',
    secondary: 'Roboto',
    code: 'monospace',
  },

  rounded: {
    0: '0px',
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '12px',
    xl: '12px',
    circle: '1000rem',
  },

  application: {
    menuWidth: '200px',
    miniMenuWidth: '50px',
    menuAdditionalWidth: '4px',
    menuHeight: '36px',
    hoveredMenuWidth: '204px',
    hoveredMiniMenuWidth: '54px',
    headerHeight: '65px',
    menuTransition: 'width 0.5s ease',
  },

  skeleton: {
    baseColor: '#F0F0F0',
    hightlightColor: '#E0E0E0',
    duration: '1.2',
  },

  transition: 'all 0.4s ease-in-out',

  // textSize's from Mehdi' system
  // * tiny
  // * caption
  // * body
  // * subtitle
  // * title

  textSize: {
    size: {
      half: '0.5rem',
      tiny: '0.5625rem',
      small: '0.625rem',
      caption: '0.6875rem',
      body: '0.75rem',
      input: '0.875rem',
      paragraph: '1rem',
      subtitle: '1rem',
      subheader: '1.25rem',
      title: '1.5rem',
      heading: '1.375rem',
      subheading: '1.25rem',
      display1: '2rem',
      display2: '2.5rem',
      display3: '2.875rem',
      display4: '3.5rem',
    },
    height: {
      half: '0.5rem',
      tiny: '0.625rem',
      small: '0.625rem',
      caption: '0.8125rem',
      body: '0.875rem',
      input: '.875rem',
      paragraph: '1.625rem',
      subtitle: '1.25rem',
      subheader: '1.875rem',
      title: '1.75rem',
      heading: '1.75rem',
      subheading: '1.5rem',
      display1: '3rem',
      display2: '3.25rem',
      display3: '3.5rem',
      display4: '4rem',
    },
  },
};

export default theme;
